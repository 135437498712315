define(['app'], (app) => {

  const athenaProductFreeDelivery = () => {
    const component = {};

    const _selectors = {
      freeDeliveryMsg: '[data-js-element="athenaProductFreeDelivery_messageText"]',
      freeDeliveryMsgIcon: '[data-js-element="athenaProductFreeDelivery_messageIcon"]'
    };

    const _props = {
      digitalProp: null,
      physicalProp: null
    };

    const _classes = {
      iconClass: 'athenaProductFreeDelivery_messageIcon'
    };

    const _modifiers = {
      digital: 'digitalDownload',
      default: 'default'
    };

    const _channels = {
      update: 'athenaProductFreeDelivery/update'
    };

    const _init = (element) => {
      component.element = element;

      component.getProps();
      app.subscribe(_channels.update, component.updateFreeDeliveryMessage);

      return component;
    };

    const _getProps = () => {
      Object.keys(_props).forEach(key => {
        const prop = component.element.querySelector(`[data-property-key="${key}"]`);

        if (prop) {
          _props[key] = prop.getAttribute('data-property-value');
        }
      });
    };

    const _updateFreeDeliveryMessage = (response) => {
      const textEl = component.element.querySelector(_selectors.freeDeliveryMsg);
      const iconEl = component.element.querySelector(_selectors.freeDeliveryMsgIcon);

      if (response === 'Digital') {
        textEl.innerText = _props.digitalProp;
        iconEl.classList = `${_classes.iconClass} ${_classes.iconClass}-${_modifiers.digital}`;
      } else {
        textEl.innerText = _props.physicalProp;
        iconEl.classList = `${_classes.iconClass} ${_classes.iconClass}-${_modifiers.default}`;
      }
    };

    component.init = _init;
    component.getProps = _getProps;
    component.updateFreeDeliveryMessage = _updateFreeDeliveryMessage;

    return component;
  };

  return athenaProductFreeDelivery;
});

